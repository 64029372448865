import React from 'react';
import { useRefresh } from 'react-admin';
import { EditButton as Base } from 'react-admin';

const EditButton = (props) => {
  const refresh = useRefresh();
  const handleEdit = () => {
    refresh();
  };

  return <Base {...props} onClick={handleEdit} />;
};

export default EditButton;