import React from 'react'
import restProvider from 'ra-data-simple-rest'
import UserList from './Components/UserList'
import UserCreate from './Components/UserCreate'
import UserEdit from './Components/UserEdit'
import ModuleList from './Components/ModuleList'
import ModuleCreate from './Components/ModuleCreate'
import ModuleEdit from './Components/ModuleEdit'
import SessionShow from './Components/SessionShow'
import SessionList from './Components/SessionList'
import SessionCreate from './Components/SessionCreate'
import SessionEdit from './Components/SessionEdit'
import FilesList from './Components/FilesList'
import FilesCreate from './Components/FilesCreate'
import FilesEdit from './Components/FilesEdit'
import FilesShow from './Components/FilesShow'
import Dashboard from './Components/Dashboard'
import AuthProvider from './Components/AuthProvider'
import UserIcon from "@mui/icons-material/Group";
import CastleIcon from '@mui/icons-material/Castle';
import FestivalIcon from '@mui/icons-material/Festival';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import InMemoryJWT from './Components/InMemoryJWT';
import authFetchJson from './Components/authFetchJson';

import {
    Admin,
    Resource,
    defaultTheme,
} from 'react-admin'


const theme = {
    ...defaultTheme,
    palette: {
        mode: 'dark',
    },
};

function App() {
    return (
        <Admin
            theme={theme}
            authProvider={AuthProvider}
            dataProvider={restProvider('/api', authFetchJson)}
            dashboard={Dashboard}
        >
            <Resource
                name   = 'users'
                icon   = {UserIcon}
                list   = {UserList} 
                create = {UserCreate}
                edit   = {UserEdit}
            />
            <Resource
                name   = 'files'
                icon   = {FolderCopyIcon}
                show   = {FilesShow}
                list   = {FilesList}
                create = {FilesCreate}
                edit   = {FilesEdit}
            />        
            <Resource
                name   = 'modules'
                icon   = {CastleIcon}
                list   = {ModuleList}
                create = {ModuleCreate}
                edit   = {ModuleEdit}
            />
            <Resource
                name   = 'sessions'
                icon   = {FestivalIcon}                
                list   = {SessionList}
                create = {SessionCreate}
                edit   = {SessionEdit}
            />
        </Admin>
    );
}

export default App;
