import React from 'react'
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    ArrayInput,
    SimpleFormIterator,
    required,
    minLength,
    maxLength,
} from 'react-admin'
import {
    RichTextInput,
} from 'ra-input-rich-text';


const validateName = [required(), minLength(2)];

const SessionCreate = (props) => {
    return <Create title = "Add session" {...props}>
        <SimpleForm>
            <TextInput source = 'name' validate={validateName}/>
            <RichTextInput source = 'description'/>
            <ReferenceInput
                source="moduleId"
                reference="modules"
            >
                <SelectInput optionValue="id" optionText="name" validate={required()}/>
            </ReferenceInput>
            <ReferenceInput
                source="masterId"
                recordRepresentation="name"
                reference="users"
                validate={required()}
            >
                <SelectInput optionValue="id" optionText="name"/>
            </ReferenceInput>
            <ReferenceInput
                source="sessionFileId"
                reference="files"
                filter={{type: "Session"}}
            >
                <SelectInput optionValue="id" optionText="name"/>
            </ReferenceInput>
            <ArrayInput source="players">
                <SimpleFormIterator inline>
                    <SelectInput source="role" validate={required()} choices={[
                        { id: 'Squaddie', name: 'Squaddie' },
                        { id: 'Officer', name: 'Officer' },
                        { id: 'Leader', name: 'Leader' },
                    ]} />                
                    <ReferenceInput  source="id" 
                        reference="users"
                        filter={{role: ["Player","PowerPlayer","Master", "Admin", "SuperAdmin"]}}
                    >
                        <SelectInput optionValue="id" optionText="name" validate={required()}/>
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>            
        </SimpleForm>
    </Create>
}

export default SessionCreate