import {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    FullFileBrowser,
    FileList,
    FileToolbar,
    ChonkyActions,
    FileContextMenu,
    ChonkyIconName,
    defineFileAction,
    setChonkyDefaults,
} from "chonky";

import {
    useShowContext,
    useRecordContext,
} from 'react-admin';

import { ChonkyIconFA } from "chonky-icon-fontawesome";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

const FileBrowser = ({onOpenContent, ...props}) => {
    const record = useRecordContext();
    const fileTree = `/api/filestorage/${record.id}/tree`;

    const [filesData, setFilesData] = useState([]);
    const [files, setFiles] = useState(null);
    const [folderChain, setFolderChain] = useState([]);
    const [contentUrl, setContentUrl] = useState(null);
    const [contentType, setContentType] = useState(null);
    
    const findFile = (data, fileId, path) => {
        for (let i = 0; i < data.length; i++) {
            const folder = data[i];
            const entry = { id: folder.id, name: folder.name, isDir: folder.isDir };
            const modpath = [...path, entry];
            if (folder.id === fileId) {
                return { file: folder, entry, path };
            } else if (folder.isDir && folder.files) {
                let returnValues = findFile(folder.files, fileId, modpath);
                if (returnValues) {
                    return returnValues;
                }
            }
        }
        return null;
    };

    const openContentCallback = useCallback((contentUrl, contentType, content) => {
        console.log("openContentCallback", {contentUrl, contentType, content});
        onOpenContent(contentUrl, contentType, content);
    });

    const handleFileAction = (data) => {
        if (data.id === ChonkyActions.OpenFiles.id) {
            const targetFile = data.payload.targetFile;
            if (targetFile.isDir)
            {
                const found = findFile(filesData, targetFile.id, []);
                if (found != null && found.file.isDir)
                {
                    setFiles(found.file.files);
                    setFolderChain([...found.path, found.entry]);
                }
            } else {
                setContentUrl(`${record.path}/${targetFile.path}`);
                setContentType(targetFile.name.split('.').pop());
            }
        }
    };

    useEffect(() => {
        fetch(fileTree)
            .then((response) => response.json())
            .then((json) => {
                setFilesData([json]);
                setFiles(json.files);
                setFolderChain([{ id: json.id, name: json.name }]);
            });
    }, []);
    
    useEffect(() =>
    {
        if (contentUrl)
        {
            console.log("fetch", contentUrl, contentType);
            fetch(contentUrl)
                .then((response) => response.text())
                .then((text) => {
                    console.log("fetch", {text});
                    openContentCallback(contentUrl, contentType, text);
                })
                .catch((error) => console.error("Error fetching file content:", error));
        }
    }, [contentUrl, contentType]);

    const actionsToDisable = [
        ChonkyActions.SelectAllFiles,
        ChonkyActions.ToggleShowFoldersFirst,
        ChonkyActions.ToggleHiddenFiles,
        ChonkyActions.SortFilesByDate,
        ChonkyActions.SortFilesByName,
        ChonkyActions.SortFilesBySize,
        ChonkyActions.EnableListView,
        ChonkyActions.EnableGridView,
        ChonkyActions.EnableCompactView,
    ];

    const fileActions = [
        ChonkyActions.CreateFolder,
        ChonkyActions.UploadFiles,
        ChonkyActions.DownloadFiles,
        ChonkyActions.DeleteFiles,
    ];

    return (  
        <FullFileBrowser
            darkMode
            disableDefaultFileActions={actionsToDisable}
            defaultFileViewActionId={ChonkyActions.EnableListView.id}
            files={files}
            folderChain={folderChain}
            onFileAction={handleFileAction}
            fileActions={fileActions}
            {...props}
        >
            <FileToolbar />
            <FileList />
            <FileContextMenu />
        </FullFileBrowser>
    );
};

export default FileBrowser;
