import React from 'react'
import {
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    CreateButton,
    ExportButton,
    usePermissions,
} from 'react-admin'
import Permissions from './permissions';
import { EnableIf } from './authPrimitives';

const ResourceListActions = () => {
    const p = Permissions.enshure(usePermissions());
    return (
        <TopToolbar>
            <SelectColumnsButton />
            <FilterButton />
            <EnableIf permissions={p} atLeast="Master">
                <CreateButton />
            </EnableIf>
        </TopToolbar>
    );
};

export default ResourceListActions;