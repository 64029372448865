import InMemoryJWT from './InMemoryJWT';
import {
    fetchUtils,
} from 'react-admin'

const authFetchJson = (url, options = {}) => {
    return InMemoryJWT
        .waitForTokenRefresh()
        .then(() => {
            if (options.headers == null)
            {
                options.headers = new Headers({ Accept: 'application/json' });
            }
            options.headers.append('Authorization', `Bearer ${InMemoryJWT.getToken()}`);
            return fetchUtils.fetchJson(url, options);
        });
};

export default authFetchJson;