import Permissions from './permissions';

const InMemoryJWT = () => {
    let _user = null;
    let _userId = null;
    let _userRole = null;
    let _refreshToken = null;
    let _token = null;
    let isRefreshing = null;
    let logoutEventName = 'ra-logout';
    let refreshTimeOutId;
    let _permissions = null;

    // This countdown feature is used to renew the JWT before it's no longer valid
    // in a way that is transparent to the user.
    const refreshToken = (delay) => {
        refreshTimeOutId = window.setTimeout(
            getRefreshedToken,
            delay * 1000 - 5000
        ); // Validity period of the token in seconds, minus 5 seconds
    };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (_token ==  null)
        {
            if (_refreshToken == null)
            {
                _refreshToken = window.localStorage.getItem("refreshToken");
                console.log({_refreshToken});
                if (_refreshToken != null)
                {
                    return getRefreshedToken().then(() => {
                        isRefreshing = null;
                        return true;
                    });
                }
            }
            return Promise.resolve();
        }
        
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    }

    // The method make a call to the refresh-token endpoint
    // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
    const getRefreshedToken = () => {
        const request = new Request('/api/login', {
            method: 'PUT',
            body: JSON.stringify({ 'token': _refreshToken }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            
            credentials: 'include',
        });

        isRefreshing = fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    ereaseToken();
                    global.console.log(
                        'Token renewal failure'
                    );
                    return { token: null };
                }
                return response.json();
            })
            .then((v) => {
                if (v.token) {
                    global.console.log(
                        'Token renewed'
                    );                   
                    setToken(v);
                    return true;
                }
                ereaseToken();
                return false;
            });

        return isRefreshing;
    };

    const getToken = () => _token;

    const setToken = (v) => {
        _user = v.user;
        _userRole = v.user.role;
        _userId = v.user.id;
        _refreshToken = v.refreshToken;        
        _token = v.token;
        _permissions = new Permissions(_userId, _userRole);
        window.localStorage.setItem("refreshToken", _refreshToken);
        refreshToken(v.tokenLifeTime > 0 ? v.tokenLifeTime : 60);
        return true;
    };

    const getPermissions = () => _permissions;
    const getIdentity = () => _user;

    const ereaseToken = () => {
        window.localStorage.removeItem("refreshToken");
        _userRole = null;
        _userId = null;
        _token = null;        
        _refreshToken = null;
        abordRefreshToken();        
        return true;
    }

    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            _token = null;
        }
    });

    return {
        ereaseToken,
        getRefreshedToken,
        getToken,
        setToken,
        waitForTokenRefresh,
        getPermissions,
        getIdentity,
    }
};

export default InMemoryJWT();
