import React from 'react';
import {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
  useRecordContext,
  Show,
  SimpleShowLayout,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';
import { Container, Grid } from '@material-ui/core';
import FileBrowser from './FileBrowser';
import FileEditor from './FileEditor';

const Title = () => {
  const record = useRecordContext();
  return <span>{record?.name ?? "Unknown"}</span>;
};

const ShowActions = () =>
{
    <Toolbar>
        <SaveButton />
        <DeleteButton />
    </Toolbar>    
};

const FilesShow = (props) => {
    const [content, setContent] = useState("");
    const [language, setLanguage] = useState("plainText");      
    
    const getLanguageByFileType = (type) =>
    {
        switch (type)
        {
            case "lua": return "lua";
            case "json": return "json";
            case "csv": return "csv";
            case "ini": return "ini";
        }
       
        return "plaintext";
    };

    const onOpenContent = (contentUrl, contentType, content) => {
        const lang = getLanguageByFileType(contentType);
        console.log("onOpenFile", { contentUrl, contentType, lang, content });
        setContent(content);
        setLanguage(lang);
    };

    return (
        <Show title={<Title />} actions={<ShowActions />} {...props}>
            <SimpleShowLayout>
                <Container disableGutters maxWidth={false}>
                    <Grid container spacing={0}>
                        <Grid item xs="3" sm="3" md="3" lg="3">
                            <FileBrowser
                                width="100hw"
                                height="90vh"
                                onOpenContent={onOpenContent}
                            />
                        </Grid>                        
                        <Grid item xs="9" sm="9" md="9" lg="9">
                            <FileEditor
                                width="100hw"
                                height="90vh"
                                content={content}
                                language={language}
                            />
                        </Grid>
                    </Grid>
                </Container>            
            </SimpleShowLayout>
        </Show>
    );
};

export default FilesShow;
