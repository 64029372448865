import React from 'react'
import {
    List,
    DatagridConfigurable,
    TextField,
    EmailField,
    SelectField,
    DeleteButton,
    SelectColumnsButton,
    FilterButton,
    CreateButton,
    ExportButton,
    TextInput,
    TopToolbar,
    SelectArrayInput,
    usePermissions,
} from 'react-admin'

import EditButton from './EditButton';
import ListActionToolbar from './ListActionToolbar';
import { EnableIf } from './authPrimitives';
import Permissions from './permissions';
import {
    userRoleChoises,
} from './commonChoises';

const UserListActions = () => {
    const p = Permissions.enshure(usePermissions());
    return (
        <TopToolbar>
            <SelectColumnsButton />
            <FilterButton />
            <EnableIf permissions={p} atLeast="Admin">
                <CreateButton />
            </EnableIf>
        </TopToolbar>
    );
};

const UsersFilters = [
    <TextInput label="Name" source="name"/>,
    <TextInput label="E-Mail" source="email"/>,
    <SelectArrayInput source="role" choices={userRoleChoises} />    
];

const UserList = (props) => {
    const p = Permissions.enshure(usePermissions());
    return <List {...props} actions={<UserListActions/>} filters={UsersFilters}>
        <DatagridConfigurable>
            <TextField source = 'name' />
            <EmailField source = 'email' />
            <SelectField source="role" choices={userRoleChoises} />
            <ListActionToolbar>
                <EnableIf permissions={p} ownerField="id" atLeast="Admin">
                    <EditButton basePath='/users'/>
                </EnableIf>
                <EnableIf permissions={p} atLeast="SuperAdmin">
                    <DeleteButton basePath="/users" />
                </EnableIf>
            </ListActionToolbar>
        </DatagridConfigurable>
    </List>
}

export default UserList