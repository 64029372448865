import React from 'react'
import {
    ChipField,
    ReferenceField,
    useRecordContext,
} from 'react-admin'
import ColoredChipField from './ColoredChipField'

const PlayerInfoField = (props) => {
    const record = useRecordContext();
    const playerRole =  record.role;    
    
    var color = null;
    switch (playerRole)
    {
    case 'Squaddie':
      color = "rgba(0,255,0,0.1)";
      //color = "#008000";
      break;
  
    case 'Officer':
      color = "rgba(255,0,0,0.1)";
      //color = "#800000";
      break;
    
    case 'Leader':
      color = "rgba(0,0,255,0.1)";
      //color = "#000080";
      break;
    }

    //<ChipField source="name"/>

    return (
        <ReferenceField source="id" reference="users">
            <ColoredChipField source="name" color={color}/>
        </ReferenceField>
    );
}

export default PlayerInfoField;