import React from 'react'
import { Children, cloneElement } from "react";
import Permissions from './permissions';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';

export const disabledIf = (expr) => (expr ? {'disabled':true} : {});
export const enableIf = (expr) => (expr ? {} : {'disabled':true});

export const EnableIf = ({ children, permissions, atLeast, ownerField }) => {
    const context = useRecordContext();
    
    const p = permissions;
    const ownerId = context ? get(context, ownerField) : null;
    const enabled = p.atLeast(atLeast) || p.isOwner(ownerId);
    const extraProps = enableIf(enabled);
    
    return <>{Children.map(children, (child) => cloneElement(child, {...extraProps}))}</>;
};