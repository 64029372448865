import { useRecordContext } from 'react-admin'

class Permissions {
  constructor(id, role) {
    this.id = id;
    this.role = role;
    this.roleId = Permissions.getRoleId(role);
  }

  static enshure({ isLoading, permissions })
  {
      return (permissions ?? new Permissions(null, null));
  }

  static getRoleId(role) {
    switch (role) {
      case 'Guest':
        return 0;
      case 'Player':
        return 1;
      case 'PowerPlayer':
        return 2;
      case 'Master':
        return 3;
      case 'Admin':
        return 4;
      case 'SuperAdmin':
        return 5;
      default:
        return 0;
    }
  }

  isOwner(id) {
    return this.id != null && id != null && id == this.id;
  }

  atLeast(role) {
    return Permissions.getRoleId(role) <= this.roleId;
  }
}

export default Permissions;
