import React from 'react'
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    required,
    minLength,
    maxLength,
    email,
} from 'react-admin'
import {
    RichTextInput,
} from 'ra-input-rich-text';
import {
    fileTypeChoises,
    filePurposeChoises,
} from './commonChoises';


const validateName = [required(), minLength(2)];

const FilesCreate = (props) => {
    return <Create title = "Add File" {...props}>
        <SimpleForm>
            <TextInput source = 'name' validate={validateName}/>
            <RichTextInput source = 'description'/>
            <SelectInput source="type" validate={required()} choices={fileTypeChoises} />
            <SelectInput source="purpose" validate={required()} choices={filePurposeChoises} />            
            <ReferenceInput
                source="templateId"
                recordRepresentation="name"
                reference="files"
            >
                <SelectInput optionValue="id" optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
}

export default FilesCreate