import React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    required,
    minLength,
    maxLength,
} from 'react-admin'
import {
    RichTextInput,
} from 'ra-input-rich-text';

const validateName = [required(), minLength(2)];

const ModuleEdit = (props) => {
    return <Edit title = "Edit module" {...props}>
        <SimpleForm>
            <TextInput source = 'name' validate={validateName}/>
            <RichTextInput source = 'description'/>
            <ReferenceInput
                source="masterId"
                recordRepresentation="name"
                reference="users"
                validate={required()}
            >
                <SelectInput optionValue="id" optionText="name"/>
            </ReferenceInput>
            <ReferenceInput
                source="coreFileId"
                recordRepresentation="name"
                reference="files"
                validate={required()}
                filter={{ type: "Core" }}
            >
                <SelectInput optionValue="id" optionText="name"/>
            </ReferenceInput>
            <ReferenceInput
                source="rndFileId"
                reference="files"
                validate={required()}
                filter={{ type: "RNDGen" }}
            >
                <SelectInput optionValue="id" optionText="name"/>
            </ReferenceInput>
            <ReferenceInput
                source="moduleFileId"
                recordRepresentation="name"
                reference="files"
                validate={required()}
                filter={{ type: "Module" }}
            >
                <SelectInput optionValue="id" optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
}

export default ModuleEdit