const fileTypeChoises = [
    { id: 'Core', name: 'Core' },
    { id: 'RNDGen', name: 'RNDGen' },
    { id: 'Module', name: 'Module' },
    { id: 'Session', name: 'Session' },
    { id: 'Personal', name: 'Personal' },    
];

const filePurposeChoises = [
    { id: 'Common', name: 'Common' },
    { id: 'Template', name: 'Template' },
];

const userRoleChoises = [
    { id: 'Guest', name: 'Guest' },
    { id: 'Player', name: 'Player' },
    { id: 'PowerPlayer', name: 'Power Player' },
    { id: 'Master', name: 'Master' },
    { id: 'Admin', name: 'Admin' },
    { id: 'SuperAdmin', name: 'Super Admin' },
];

const playerRoleChoises = [
    { id: 'Squaddie', name: 'Squaddie' },
    { id: 'Officer', name: 'Officer' },
    { id: 'Leader', name: 'Leader' },
];

export
{
    fileTypeChoises,
    filePurposeChoises,
    userRoleChoises,
    playerRoleChoises,
}