import React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    required,
    minLength,
    maxLength,
    email,
} from 'react-admin'
import {
    RichTextInput,
} from 'ra-input-rich-text';
import {
    fileTypeChoises,
    filePurposeChoises,
} from './commonChoises';

const validateName = [required(), minLength(2)];

const FilesEdit = (props) => {
    return <Edit title = "Edit Files" {...props}>
        <SimpleForm>
            <TextInput source = 'name' validate={validateName}/>
            <RichTextInput source = 'description'/>
            <SelectInput source="type" validate={required()} choices={fileTypeChoises} />
            <SelectInput source="purpose" validate={required()} choices={filePurposeChoises} />            
            <ReferenceInput
                source="ownerId"
                recordRepresentation="name"
                reference="users"
                validate={required()}
            >
                <SelectInput optionValue="id" optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
}

export default FilesEdit