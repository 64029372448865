import React from 'react';
import { useState, useEffect, useRef, useCallback  } from 'react';
import Editor from '@monaco-editor/react';

const FileEditor = ({content, language, ...props}) =>
{
    const [isEditorReady, setIsEditorReady] = useState(false);
    const editorRef = useRef(null);
    
    const setContent = (filePath, fileType) => {
        console.log({ filePath, fileType });
    }
    
    const handleEditorDidMount = (editor, monaco) => {
        setIsEditorReady(true);
        
        monaco.languages.register({ id: 'ini' });
        monaco.languages.setMonarchTokensProvider('ini', {
            tokenizer: {
            root: [
                [/^\[.*\]/, 'section'],
                [/[^=]+/, 'key'],
                [/\=.*/, 'value'],
            ],
            },
        });
        
        editorRef.current = editor;
    };

    const options = {
        autoIndent: 'full',
        contextmenu: true,
        fontFamily: 'cascadia-code, consolas, monospace',
        fontSize: 13,
        lineHeight: 24,
        hideCursorInOverviewRuler: true,
        matchBrackets: 'always',
        minimap: {
            enabled: true,
        },
        scrollbar: {
            horizontalSliderSize: 4,
            verticalSliderSize: 18,
        },
        selectOnLineNumbers: true,
        roundedSelection: false,
        readOnly: false,
        cursorStyle: 'line',
        automaticLayout: true,
    };
   
    return (
        <Editor
            theme="vs-dark"
            value={content}
            language={language}
            onMount={handleEditorDidMount}
            options={options}
            {...props}
        />
    );
};

export default FileEditor;