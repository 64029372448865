import React from 'react';
import {
    List,
    Datagrid,
    DatagridConfigurable,
    TextField,
    TextInput,
    EmailField,
    SelectField,
    ShowButton,
    DeleteButton,
    RichTextField,
    ReferenceField,
    usePermissions,
    useRecordContext,
} from 'react-admin';
import {
    fileTypeChoises,
    filePurposeChoises,
} from './commonChoises';
import Box from '@mui/system/Box';
import Permissions from './permissions';
import { EnableIf } from './authPrimitives';
import EditButton from './EditButton';
import ListActionToolbar from './ListActionToolbar';
import ResourceListActions from './ResourceListActions';

const FilesFilters = [
    <TextInput label="Name" source="name"/>,
    <TextInput label="Description" source="description"/>,
    <SelectField source="purpose" choices={filePurposeChoises} />,
    <SelectField source="type" choices={fileTypeChoises} />,
    <ReferenceField source="ownerId" reference="users">
        <TextField source="name" />
    </ReferenceField>    
];

const FilesList = (props) => {
    const p = Permissions.enshure(usePermissions());
    return <List {...props} actions={<ResourceListActions/>} filters={FilesFilters}>
        <DatagridConfigurable>
            <TextField source = 'name'/>
            <RichTextField source = 'description'/>
            <SelectField source="type" choices={fileTypeChoises} />
            <SelectField source="purpose" choices={filePurposeChoises} />
            <ReferenceField source="ownerId" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <ListActionToolbar source="actions">
                <EnableIf permissions={p} ownerField="ownerId" atLeast="SuperAdmin">
                    <ShowButton   basePath='/files'/>
                    <EditButton   basePath='/files' />
                    <DeleteButton basePath="/files" />
                </EnableIf>
            </ListActionToolbar>
        </DatagridConfigurable>
    </List>
}

export default FilesList