import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    PasswordInput,
    required,
    minLength,
    maxLength,
    email,
} from 'react-admin'
import { userRoleChoises } from './commonChoises';

const validateName = [required(), minLength(2)];
const validateEmail = [required(), email()];

const PasswordBox = ({ handlePasswordChange, handleConfirmPasswordChange, validatePasswords, error }) =>
{
    return (
        <Box display="flex">
            <Box flex={1} mr="0.5em">
                <PasswordInput source="password" 
                    onChange={handlePasswordChange}
                    fullWidth
                />
            </Box>
            <Box flex={1} ml="0.5em">
                <PasswordInput
                    source="confirm_password"
                    onChange={handleConfirmPasswordChange}
                    onBlur={validatePasswords}
                    error={error}
                    fullWidth
                />
            </Box>
        </Box>
    );
};


const UserEdit = (props) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const validatePasswords = () => {
        if (password !== confirmPassword) {
            setError('Passwords do not match');
        } else {
        setError('');
        }
    };    
    
    return <Edit title = "Edit user" {...props}>
        <SimpleForm>
            <Grid container width="100%" spacing={2}>
                <Grid item xs={8}>
                    <Typography variant="h6" gutterBottom>
                        Identity
                    </Typography>
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            <TextInput
                                source = 'name'
                                isRequired
                                fullWidth
                                validate={validateName}
                            />
                        </Box>
                        <Box flex={1} ml="0.5em">
                            <TextInput
                                type="email"
                                source="email"
                                validate={validateEmail}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml="0.5em">
                            <SelectInput source="role" fullWidth validate={required()} choices={userRoleChoises} />
                        </Box>
                    </Box>
                    <Box mt="1em" />
                    <Typography variant="h6" gutterBottom>
                        Change Password
                        <PasswordBox
                            handlePasswordChange={handlePasswordChange}
                            handleConfirmPasswordChange={handleConfirmPasswordChange}
                            validatePasswords={validatePasswords}
                            error={error}                    
                        />
                    </Typography>
                </Grid>
            </Grid>
        </SimpleForm>   
    </Edit>
}

export default UserEdit