import React from 'react'
import {
    List,
    DatagridConfigurable,
    TextField,
    EmailField,
    SelectField,
    DeleteButton,
    RichTextField,
    ReferenceField,
    TextInput,
    TopToolbar,
    SelectColumnsButton,
    ArrayField,
    SingleFieldList,
    usePermissions,
} from 'react-admin'

import { EnableIf } from './authPrimitives';
import EditButton from './EditButton';
import Permissions from './permissions';
import ListActionToolbar from './ListActionToolbar';
import PlayerInfoField from './PlayerInfoField';
import ResourceListActions from './ResourceListActions';

const SessionFilters = [
    <TextInput label="Name" source="name"/>,
    <TextInput label="Description" source="description"/>,
];

const SessionList = (props) => {
    const p = Permissions.enshure(usePermissions());
    return <List {...props} actions={<ResourceListActions/>} filters={SessionFilters}>
        <DatagridConfigurable>
            <TextField source = 'name' />
            <RichTextField source = 'description'/>
            <ReferenceField source="moduleId" recordRepresentation="name" reference="modules"  >
                <TextField source="name" />
            </ReferenceField>            
            <ReferenceField source="sessionFileId" recordRepresentation="name" reference="files"  >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="masterId" reference="users" >
                <TextField source="name" />
            </ReferenceField>            
            <ArrayField source="players">
                <SingleFieldList>
                    <PlayerInfoField />
                </SingleFieldList>
            </ArrayField>
            <ListActionToolbar  source="actions">
                <EnableIf permissions={p} ownerField="ownerId" atLeast="SuperAdmin">
                    <EditButton   basepath='/sessions' />
                    <DeleteButton basepath='/sessions' />
                </EnableIf>
            </ListActionToolbar>
        </DatagridConfigurable>
    </List>
}

export default SessionList