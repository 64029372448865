import React from 'react'
import {
    ChipField,
} from 'react-admin'

const ColoredChipField = (props) => {
  const { color, ...rest } = props;
  if (color != null) {
    return (<ChipField sx={{ bgcolor: color }} {...rest} />);
  }
  return (<ChipField {...rest} />);
};

export default ColoredChipField;