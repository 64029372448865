import InMemoryJWT from './InMemoryJWT';

const doLogout = () => {
    if (InMemoryJWT.getToken() != null)
    {
        const request = new Request('/api/login', {
            method: 'DELETE',
            credentials: 'include',
            headers: {"bearer": InMemoryJWT.getToken() },
        });
        return fetch(request);
    }
    return Promise.resolve();
};

const AuthProvider = {
    login: ({ username, password }) => {
        const request = new Request('/api/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((v) => {
                return InMemoryJWT.setToken(v);
            });
    },

    logout: () => {
        return doLogout()
            .then(() => InMemoryJWT.ereaseToken())
            .then(() => '/login');
    },

    checkAuth: () => {
        return InMemoryJWT.waitForTokenRefresh().then(() => {
            return InMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
        });
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401) {
            InMemoryJWT.ereaseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        return InMemoryJWT.waitForTokenRefresh().then(() => {
            let permissions = InMemoryJWT.getPermissions();
            return permissions ? Promise.resolve(permissions) : Promise.reject();
        });
    },
    
    getIdentity: () => 
    {   
        return InMemoryJWT.getIdentity();
    },
};

export default AuthProvider; 
