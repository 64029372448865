import {
    Card,
    CardContent,
    CardHeader,
} from "@mui/material";

const Dashboard = () => (
  <Card>
    <CardHeader title="Welcome to the WDEServer Admin" />
    <CardContent>
    </CardContent>
  </Card>
);

export default Dashboard