import React from 'react'
import {
    List,
    DatagridConfigurable,
    TextField,
    EmailField,
    SelectField,
    ReferenceField,
    DeleteButton,
    SimpleShowLayout,
    RichTextField,
    TextInput,
    TopToolbar,
    SelectColumnsButton,
    FilterButton,
    CreateButton,
    ExportButton,
    Show,
    usePermissions,
    useRecordContext,
} from 'react-admin'
import EditButton from './EditButton';
import ResourceListActions from './ResourceListActions';
import ListActionToolbar from './ListActionToolbar';
import Permissions from './permissions';
import { EnableIf } from './authPrimitives';

const ModuleFilters = [
    <TextInput label="Name" source="name"/>,
    <TextInput label="Description" source="description"/>,
];

const ModuleList = (props) => {
    const p = Permissions.enshure(usePermissions());
    return <List {...props}  actions={<ResourceListActions/>} filters={ModuleFilters}>
        <DatagridConfigurable>
            <TextField source = 'name' />
            <RichTextField source = 'description' />
            <ReferenceField source="masterId" reference="users" >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="coreFileId" recordRepresentation="name" reference="files"  >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="rndFileId" recordRepresentation="name" reference="files"  >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="moduleFileId" recordRepresentation="name" reference="files"  >
                <TextField source="name" />
            </ReferenceField>
            <ListActionToolbar>
                <EnableIf permissions={p} ownerField="masterId" atLeast="SuperAdmin">
                    <EditButton   basePath='/modules' />
                    <DeleteButton basePath='/modules' />
                </EnableIf>
            </ListActionToolbar>
        </DatagridConfigurable>
    </List>
}

export default ModuleList